import { useState } from "react";

//components
import
{
	Text,
	Card,
	CardBody,
	FormatPercent,
	FormatTokenAmount,
	Grid,
	ValueTokenAmount,
	Flex,
	TokenIcon,
	MLWeb3,
	ValueCountdown
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MoonPoolDeposit,
	MoonPoolWithdraw,
	ButtonMoonPoolClaim
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule,
	SectionHeader
} from "../../dApp/components";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonPool, ModuleEvents_MoonPool } from "@MoonVault/dapp-widgets-lib";

//styles
import styles from "../Pages.module.css";

const PoolStat = ({title, subText, secondary, children}) =>
{
	return (
		<Grid gap="0px">
			<Text
				color={3}
				align="center"
			>
				{title}
			</Text>

			<Text
				color={(!!secondary ? 2 : 1)}
				align="center"
			>
				{children}
			</Text>

			{subText &&
				<Text
					color={2}
					size={-1}
					align="center"
				>
					{subText}
				</Text>
			}
		</Grid>
	);
};

const PoolStat2 = ({title, subText, secondary, children}) =>
{
	return (
		<Flex justify="space-between">
			<Text
				color={3}
				align="center"
			>
				{title}
			</Text>

			<Grid gap="0px">
				<Text
					color={(!!secondary ? 2 : 1)}
					align="center"
				>
					{children}
				</Text>

				{subText &&
					<Text
						color={2}
						size={-1}
						align="center"
					>
						{subText}
					</Text>
				}
			</Grid>
		</Flex>
	);
};

const PoolHeader = () =>
{
	//context
	const pool = useMoonPool();

	return (
		<Flex>
			<TokenIcon
				token={pool.depositToken}
				style={{ width: "50px", height: "50px" }}
			/>

			<Grid gap="0px">
				<Text
					color={3}
					align="center"
				>
					Stake {pool.depositToken?.symbol}
				</Text>

				<Text
					color={1}
					align="center"
				>
					Earn {pool.rewardToken?.symbol}
				</Text>
			</Grid>

			<TokenIcon
				token={pool.rewardToken}
				style={{ width: "50px", height: "50px" }}
			/>
		</Flex>
	);
};

const PoolStat_Deposit = ({data}) =>
{
	//context
	const pool = useMoonPool();

	return (
		<PoolStat
			title="Deposit"
			subText=
			{
				<FormatPercent
					value={data.userShare}
					precision={4}
				/>
			}
		>
			<ValueTokenAmount
				token={pool.depositToken}
				value={data.userDeposit}
				shorten={true}
			/>
		</PoolStat>
	);
};

const PoolStat_Pending = ({data}) =>
{
	//context
	const pool = useMoonPool();

	return (
		<PoolStat
			title="Pending"
			subText=
			{data.userClaimed.isZero()
				? <>0 claimed</>
				: <>
					+
					<FormatTokenAmount
						token={pool.rewardToken}
						value={data.userClaimed}
						shorten={true}
					/>
				</>
			}
		>
			<ValueTokenAmount
				token={pool.depositToken}
				value={data.userPending}
				shorten={true}
			/>
		</PoolStat>
	);
};

const PoolStat_Event = ({data}) =>
{
	return (
		<PoolStat
			title="Event"
			secondary={data.beforeStart || data.afterEnd}
			subText=
			{
				data.beforeStart
					? <>until start</>
					: data.afterEnd
						? <></>
						: <>until end</>
			}
		>
			{data.afterEnd
				? <>finished</>
				: data.beforeStart
					? <ValueCountdown target={data.startTime} />
					: <ValueCountdown target={data.endTime} />
			}
		</PoolStat>
	);
};

const PoolStat_Rewards = ({data}) =>
{
	//context
	const pool = useMoonPool();

	return (
		<PoolStat
			title="Rewards"
			secondary={data.currentRemainingReward.isZero()}
			subText=
			{
				<>
					{data.currentRemainingReward.isZero() &&
						<FormatTokenAmount
							token={pool.rewardToken}
							value={data.refilledReward}
							shorten={true}
						/>
					}
				</>
			}
		>
			<FormatTokenAmount
				token={pool.rewardToken}
				value={data.currentRemainingReward}
				shorten={true}
			/>
		</PoolStat>
	);
};

const PoolStat_Estimate = ({data}) =>
{
	//context
	const pool = useMoonPool();

	return (
		<PoolStat
			title="Estimate daily"
			subText=
			{
				data.userDeposit.isZero()
					? <>per 1000 {pool.depositToken?.symbol}</>
					: <>for deposit</>
			}
		>
			<ValueTokenAmount
				token={pool.depositToken}
				value=
				{
					data.userDeposit.isZero()
						? data.rewardPerMoonPerDay.mul(MLWeb3.toBN(1000))
						: data.userPending
				}
				shorten={true}
			/>
		</PoolStat>
	);
};

const StatLine = ({title, children}) =>
{
	return (
		<Flex
			className={styles.dottedHover}
			justify="space-between"
		>
			<Text
				color={1}
				size={-1}
			>
				{title}:
			</Text>

			<Text
				color={3}
				size={-1}
			>
				{children}
			</Text>
		</Flex>
	);

};

const PoolInfo = () =>
{
	//context
	const pool = useMoonPool();

	//functions
	const getData = () =>
	{
		return {
			userDeposit: pool.userDeposit,
			userPending: pool.userPending,
			userClaimed: pool.userClaimed,
			userShare: pool.userShare,
			startTime: pool.startTime,
			beforeStart: (new Date().getTime() < (pool.startTime?.getTime() || 0)),
			endTime: pool.endTime,
			afterEnd: (new Date().getTime() > (pool.endTime?.getTime() || 0)),
			currentRemainingReward: pool.currentRemainingReward,
			refilledReward: pool.refilledReward,
			rewardPerMoonPerDay: pool.rewardPerMoonPerDay
		};
	};

	//state
	const [data, setData] = useState(() => getData());

	//effect
	useEventSubscription([ModuleEvents_MoonPool.data, ModuleEvents_MoonPool.userData], () => setData(getData()), []);

	return (

			<Card>
				<CardBody>
					<Grid>
						<PoolHeader/>

						<SectionHeader />

						<Grid gap="0p">

							<Text
								size={-1}
								color={3}
							>
								Pool details:
							</Text>

							<StatLine title="Event Duration">

							</StatLine>

							<StatLine title="Event Rewards">
								<FormatTokenAmount
									token={pool.rewardToken}
									value={data.currentRemainingReward}
									shorten={true}
								/>
							</StatLine>


							<br />

							<Text
								size={-1}
								color={3}
							>
								User details:
							</Text>

							<StatLine title="Deposited">
								<ValueTokenAmount
									token={pool.depositToken}
									value={data.userDeposit}
									shorten={true}
								/>
							</StatLine>


							<StatLine title="Pool Share">
								<FormatPercent
									value={data.userShare}
									precision={4}
								/>
							</StatLine>


							<StatLine title="Daily Estimated Rewards">

							</StatLine>


							<StatLine title="Pending">
								<ValueTokenAmount
									token={pool.depositToken}
									value={data.userPending}
									shorten={true}
								/>
							</StatLine>


						</Grid>

						<ButtonMoonPoolClaim />
					</Grid>
				</CardBody>
			</Card>
	);
};

const PoolContent = () =>
{
	return (
		<Grid
			cols={1}
			responsive=
			{
				{
					md:
					{
						cols: 2
					}
				}
			}
		>
			<PoolInfo />

			<Grid>
				<MoonPoolDeposit />

				<MoonPoolWithdraw />
			</Grid>
		</Grid>
	);
};

export const Page_earn_pool = () =>
{
    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="MOON Pool"
					backgroundImage="url(/assets/page/earn/pool/header.jpg)"
				>
					Stake your MOON to earn rewards
				</MVPageHeader>
			}
		>
            <MVCheckModule module="moon_pool">
				<PoolContent />
			</MVCheckModule>
        </MVPage>
    );
}