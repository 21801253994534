import { configUI as configUI_sdk } from "@MoonLabsDev/dapp-sdk-lib";
import { configUI as configUI_widget } from "@MoonVault/dapp-widgets-lib";

import configPage from "./page";

//template & theme
import { Template_MoonVault } from "../../templates/MoonVault";

const configUI =
{
	...configUI_sdk,

    template: Template_MoonVault,
	...(!configPage.designMoonLabs && { theme: configUI_widget.theme })
};

export default configUI;