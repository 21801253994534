import { useEffect, useState } from "react";

//components
import { ProductCard } from "../ProductCard";
import { ProductInfo_Swap } from "./ProductInfo_Swap";

//framework
import { Events, MLWeb3, useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//module
import { useMoonSwap } from "@MoonVault/dapp-widgets-lib";

export const ProductCard_Swap = () =>
{
	//context
	const dApp = useDApp();
	const swap = useMoonSwap();

	//functions
	const addTopSwap = (_swaps, _secondarySwaps, _query) =>
	{
		if (_swaps.length >= 3
			|| !_query?.swapInfoGrouped?.length
			|| _query.swapInfoGrouped.length <= 1)
		{
			return;
		}

		//compare
		const si = _query.swapInfoGrouped;
		const diff = MLWeb3.getPercent(si[1].amountOut, si[0].amountOut);
		if (diff >= 0.995)
		{
			_secondarySwaps.push(
			{
				diff: diff,
				swapInfo: si,
				best: si[0],
				item: si[1]
			});
			return;
		}

		//add
		_swaps.push(
		{
			diff: diff,
			swapInfo: si,
			best: si[0],
			item: si[1]
		});
	};
	const checkSwap = async (_swaps, _secondarySwaps, _in, _out, _amount, _wrapped) =>
	{
		if (!!_out
			&& _out !== _wrapped
			&& _out !== _in
			&& _swaps.length < 3)
		{
			addTopSwap(_swaps, _secondarySwaps, await swap.querySwap(_in.address, _out.address, _amount));
		}
	};
	const getDataEmpty = () =>
	{
		if (!swap)
		{
			return null;
		}
		return {
			title: "Swap",
			description: "Find the best swaps across multiple DEXes. Swap like a pro!",
			image: "/assets/page/home/product-swap-bg.png",
			link: "/swap",
			external: false,
			data: []
		};
	};
	const getData = async () =>
	{
		const topSwaps = [];
		const secondary = [];

		if (swap !== null)
		{
			//prepare
			const stable = dApp.stableCoin;
			const wrapped = dApp.wrappedCoin;
			const usdt = dApp.findToken("USDT");
			const usdc = dApp.findToken("USDC");
			const busd = dApp.findToken("BUSD");
			const btc = dApp.findToken("BTC") || dApp.findToken("WBTC");
			const eth = dApp.findToken("ETH") || dApp.findToken("WETH");
			const bnb = dApp.findToken("BNB") || dApp.findToken("WBNB");
			const ftm = dApp.findToken("FTM") || dApp.findToken("WFTM");
			const amount_stable_10k = stable.one.mul(MLWeb3.toBN(10000));

			//query
			await checkSwap(topSwaps, secondary, stable, wrapped, amount_stable_10k);
			await checkSwap(topSwaps, secondary, stable, usdt, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, usdc, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, busd, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, btc, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, eth, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, bnb, amount_stable_10k, wrapped);
			await checkSwap(topSwaps, secondary, stable, ftm, amount_stable_10k, wrapped);

			//secondary
			if (topSwaps.length < 3)
			{
				secondary.sort((a, b) => a.diff - b.diff);
				for (let n = 0; n < secondary.length && topSwaps.length < 3; n++)
				{
					topSwaps.push(secondary[n]);
				}
			}
		}

		return {
			...getDataEmpty(),
			data: topSwaps.map(v =>
				{
					return {
						component: ProductInfo_Swap,
						value: v
					}
				}
			)
		};
	};

	//state
	const [data, setData] = useState(() => getDataEmpty());

	//effects
	useEventSubscription(Events.dApp.reload, async() => setData(await getData()), [swap]);
	useEffect(async() => setData(await getData()), [swap]);

	return (
		<>
			{swap &&
				<ProductCard
					data={data}
				/>
			}
		</>
	);
};