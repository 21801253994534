import { useEffect, useState } from "react";

//components
import { ProductCard } from "../ProductCard";
import { ProductInfo_VaultV1 } from "./ProductInfo_VaultV1";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useVaultV1 } from "@MoonVault/dapp-widgets-lib";
import { VaultV1FilterSortType, VaultV1Tags, ModuleEvents_VaultV1 } from "@MoonVault/dapp-widgets-lib";

export const ProductCard_Vaults = () =>
{
	//context
	const vaults = useVaultV1();

	//functions
	const addTopVault = (_array, _vault, _category, _top) =>
	{
		if (!_vault
			|| _array.length >= 3)
		{
			return;
		}
		_array.push(
		{
			vault: _vault,
			category: _category,
			top: _top
		});
	};
	const getData = () =>
	{
		if (vaults === null)
		{
			return null;
		}

		const vaults_farm = vaults.applyFilter({ sort: VaultV1FilterSortType.APR, tags: [VaultV1Tags.FARM] });
		const vaults_pool = vaults.applyFilter({ sort: VaultV1FilterSortType.APR, tags: [VaultV1Tags.POOL] });
		const vaults_stable = vaults.applyFilter({ sort: VaultV1FilterSortType.APR, tags: [VaultV1Tags.STABLE] });

		//#1 - #3
		const topVaults = [];
		for (let n = 0; n < 3; n++)
		{
			addTopVault(topVaults, vaults_pool[n], VaultV1Tags.POOL, 1 + n);
			addTopVault(topVaults, vaults_farm[n], VaultV1Tags.FARM, 1 + n);
			addTopVault(topVaults, vaults_stable[n], VaultV1Tags.STABLE, 1 + n);
		}

		return {
			title: "Vaults",
			description: "Our vaults automatically reinvest for you, thereby increasing your yield significantly.",
			image: "/assets/page/home/product-vault-bg.png",
			link: "/earn/vaults",
			external: false,
			data: topVaults.map(v =>
				{
					return {
						component: ProductInfo_VaultV1,
						value: v
					}
				}
			)
		};
	};

	//state
	const [data, setData] = useState(() => getData());

	//effects
	useEffect(() => setData(getData()), [vaults]);
	useEventSubscription(ModuleEvents_VaultV1.dbData, () => setData(getData()), [vaults]);

	return (
		<ProductCard
			data={data}
		/>
	);
};