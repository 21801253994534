import { useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import
{
	Grid,
	Flex
} from "@MoonLabsDev/dapp-core-lib";
import
{
	VaultV1Overview,
	VaultV1Metric,
	VaultV1MetricType,
	VaultV1Filter
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";

export const Page_earn_vaults = () =>
{
	//context
	const navigate = useNavigate();

	//state
	const [filter, setFilter] = useState(() => {});

	//handler
	const handleSelectVault = (_vaultID) =>
	{
		navigate(`/earn/vaultDetails?vault=${_vaultID}`);
	};
	const handleFilterChange = (_filter) =>
	{
		setFilter(_filter);
	};

    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Vaults"
					backgroundImage="url(/assets/page/earn/vaults/header.jpg)"
					link="mIAbBivCpm8"
				>
					Vaults help to increase your yield, by automatizing compounding for higher returns!
				</MVPageHeader>
			}
		>
            <MVCheckModule module="moon_vaultV1">
				<Grid cols={3}>
					<VaultV1Metric
						type={VaultV1MetricType.USER_REWARDS_DAY}
					/>
					<VaultV1Metric
						type={VaultV1MetricType.USER_REWARDS_YEAR}
					/>
					<VaultV1Metric
						type={VaultV1MetricType.USER_DEPOSIT}
					/>
				</Grid>

				<VaultV1Filter
					filter={filter}
					onChange={handleFilterChange}
				/>

				<VaultV1Overview
					filter={filter}
					onSelectVault={handleSelectVault}
				/>
			</MVCheckModule>
        </MVPage>
    );
}