//components
import
{
    PageHeader,
    PageContent,
    Flex,
    ErrorServiceNotAtChain,
} from "@MoonLabsDev/dapp-core-lib";
import { MoonReferralInfo } from "@MoonVault/dapp-widgets-lib";

export const Page_misc_referral = () =>
{
    return (
        <>
            <PageHeader
                title=
                {
                    <Flex>
                        Referral
                    </Flex>
                }
            >
                Help us grow and earn a part of our profits!
            </PageHeader>

            <PageContent>
                <ErrorServiceNotAtChain module="moon_referral">
                    <Flex style={{ width: "100%" }}>
                        <MoonReferralInfo />
                    </Flex>
                </ErrorServiceNotAtChain>
            </PageContent>
        </>
    );
}