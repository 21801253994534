import { useEffect, useState } from "react";

//components
import
{
    Text,
    Grid,
    Image,
	Link,
    Card,
    CardBody,
    Flex,
	Carousel,
	CardHeader,
	CardRibbon
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	SectionHeader,
	ProductCard_Vaults,
	ProductCard_Swap,
	ProductCard_Token,
	ProductCard_Escrow
} from "../dApp/components";

//framework
import
{
	useIsMobile,
	cx,
	MLFormat
} from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonAPI } from "@MoonVault/dapp-widgets-lib";

//stlyes
import styles from "src/pages/Pages.module.css";

const Launch = ({item: {date, logo, shortText, link}}) =>
{
	const dateNow = (new Date()).getTime() / 1000;
	const done = dateNow >= date;

	return (
		<Card
			ribbon=
			{
				(done
					? <CardRibbon
						type="up"
						color="rgb(40, 197, 62)"
					/>
					: null
				)
			}
		>
			<CardHeader className={styles.launchHeader}>
				{MLFormat.formatDate(new Date(date * 1000))}
			</CardHeader>
			<CardBody
				style=
				{
					{
						height: "200px",
						width: "200px",
						backgroundImage: `url("${logo}"`,
						backgroundPosition: "center",
						backgroundSize: "cover",
					}
				}
			>
				<Grid
					style=
					{
						{
							height: "100%",
							width: "100%"
						}
					}
				>
					<Text
						color={1}
						size={1}
						align="center"
						style=
						{
							{
								textShadow: "0px 0px 5px black"
							}
						}
					>
						{shortText}
					</Text>
				</Grid>
			</CardBody>
		</Card>

	);
};

const LaunchCalendar = () =>
{
	//context
	const isMobile = useIsMobile();
	const api = useMoonAPI();

	//state
	const [items, setItems] = useState(() => []);

	//effect
	useEffect(async() =>
	{
		const launch = await api.protocol_getNews();
		launch.sort((a, b) => a.date - b.date);
		setItems(launch);
	}, [api]);

	return (
		<Carousel
			style={{width: isMobile ? 300 : "100%", margin: "auto"}}
			itemsPerDot={isMobile ? 1 : 2}
			items={items}
			defaultIndex={4}
			ChildComponent={Launch}
		/>
	);
};

const OurValue = ({title, image, children}) =>
{
    return (
        <Card
            style=
            {
                {
                    width: "300px",
                    height: "400px"
                }
            }
        >
            <CardBody>
				<Grid>
					<Flex>
						<Image
							src={image}
							style=
							{
								{
									height: "200px"
								}
							}
						/>
					</Flex>

					<Text
						size={1}
						color={3}
						align="center"
					>
						{title}
					</Text>

					<Text
						size={0}
						color={1}
						align="center"
					>
						{children}
					</Text>
				</Grid>
            </CardBody>
        </Card>
    );
};

const OurValues = () =>
{
    return (
		<Flex>
			<OurValue
				title={"Accessibility"}
				image="/assets/page/home/ourValues_accessibility.png"
			>
				With helpful UI features and guides, we support new &amp; advanced investors to make well informed investments.
			</OurValue>

			<OurValue
				title={"Transparency"}
				image="/assets/page/home/ourValues_transparency.png"
			>
				We don't just copy our competition, but strive to add features never seen before in crypto
			</OurValue>

			<OurValue
				title={"Security"}
				image="/assets/page/home/ourValues_security.png"
			>
				Our Products are audited for security and we completed a KYC at Rugdoc.
			</OurValue>
		</Flex>
    );
};

const Products = () =>
{
    return (
		<Flex>
			<ProductCard_Vaults />

			<ProductCard_Token />

			<ProductCard_Swap />
		</Flex>
    );
};

const Header = () =>
{
	const isMobile = useIsMobile();
    return (
        <Flex
			direction="row"
			className={cx([styles.homeHeader])}
        >
			<Flex direction="column" basis={isMobile ? "100%": "38%"}>
				<Text
					size={2}
					color={3}
				>
					Welcome to Moon Vault
				</Text>
				<Text size={1}>
					Multi-Chain Yield Aggregator and Crypto Service Hub
				</Text>
			</Flex>
            <Image
                src="/assets/page/home/logo.gif"
                style=
                {
                    {
                        minWidth: "300px",
                        maxWidth: "95vw",
                        maxHeight: "400px"
                    }
                }
            />
        </Flex>
    );
};

export const Page_home = () =>
{
    return (
		<MVPage>
			<Header />

			<SectionHeader>
				Products
			</SectionHeader>
			<Products />

			<SectionHeader>
				Our Values
			</SectionHeader>
			<OurValues />

			<SectionHeader>
				Launch Calendar
			</SectionHeader>
			<LaunchCalendar />
		</MVPage>
    );
}