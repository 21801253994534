import React from "react";
import ReactDOM from "react-dom";

import { App } from "@MoonLabsDev/dapp-sdk-lib";

import { config } from "./dApp/config";

const getWallet = () =>
{
	const searchParams = new URLSearchParams(window?.location?.search);
	return (searchParams.has("wallet")
		? searchParams.get("wallet")
		: null
	);
}

ReactDOM.render(
	<React.StrictMode>
		<App
			config={config}
			wallet={getWallet()}
		/>
	</React.StrictMode>,
	document.getElementById("root")
);
