import { useMemo } from "react";

//components
import
{
	Flex,
	FormatFiatAmount,
	Grid,
	Text,
	TokenIcon
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonSwap } from "@MoonVault/dapp-widgets-lib";

export const ProductInfo_Swap = ({value}) =>
{
	//context
	const dApp = useDApp();
	const swap = useMoonSwap();

	//state
	const info = useMemo(() => swap.compareSwapInfo(dApp, value.swapInfo, value.item), [value]);
	const tokenFrom = useMemo(() => info.tokens[0], [info]);
	const tokenTo = useMemo(() => info.tokens[info.tokens.length - 1], [info]);

	return (
		<Flex
			gap="0px"
			style=
			{
				{
					width: "100%",
					height: "100%"
				}
			}
		>
			<TokenIcon
				token0={tokenFrom}
				token1={tokenTo}
				style=
				{
					{
						width: "32px",
						height: "32px",
						marginLeft: "10px",
						marginRight: "10px"
					}
				}
			/>

			<Grid
				gap="0px"
				style={{ flexGrow: 1 }}
			>
				<Text>
					{tokenFrom.symbol}-{(tokenTo === dApp.wrappedCoin ? dApp.coinSymbol : tokenTo.symbol)}
				</Text>

				<Text
					size={-1}
					color={2}
				>
					Save{" "}
					<FormatFiatAmount
						value={info.savedUSD}
					/>
				</Text>
			</Grid>

			<Grid
				gap="0px"
				style=
				{
					{
						marginLeft: "auto",
						marginRight: "10px",
						width: "50px"
					}
				}
			>
				<Text
					color={3}
					size={0}
					align="center"
				>
					Trade
				</Text>

				<Text
					color={1}
					size={-1}
					align="center"
				>
					<FormatFiatAmount
						value={value.best.amountInUSD}
					/>
				</Text>
			</Grid>
		</Flex>
	);
};