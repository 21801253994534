//dApp
import { configPage as config } from "@MoonVault/dapp-widgets-lib";

//pages (test)
import
{
	Page_debugInfo,
	Page_test_general,
	Page_test_extended,
	Page_test_cards,
	Page_test_charts,
	Page_test_modals
} from "@MoonLabsDev/dapp-sdk-lib";

//pages
import
{
	Page_soon,
	Page_home,
	Page_swap,
	Page_earn_vaults,
	Page_earn_vaultDetails,
	Page_earn_token,
	Page_earn_bank,
	Page_earn_pool,
	Page_tools_escrow,
	Page_analytics_protocol,
	Page_analytics_user,
	Page_misc_referral
} from "../../pages";

const devMode = false;
const designMoonLabs = false;
const configPage =
{
	...config,

	themeColor: "#000000",
	logo: "/assets/page/logo.png",
	devMode: devMode,
	designMoonLabs: designMoonLabs,
	preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
	fonts:
    [
		"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
    ],
    pages:
    [
        { path: "home", page: Page_home },
        { path: "debugInfo", page: Page_debugInfo },

        { path: "tests/general", page: Page_test_general },
		{ path: "tests/extended", page: Page_test_extended },
        { path: "tests/cards", page: Page_test_cards },
        { path: "tests/charts", page: Page_test_charts },
        { path: "tests/modals", page: Page_test_modals },

        { path: "swap", page: Page_swap },

        { path: "earn/vaults", page: Page_earn_vaults },
		{ path: "earn/vaultDetails", page: Page_earn_vaultDetails },
        { path: "earn/pool", page: Page_earn_pool },
        { path: "earn/token", page: Page_earn_token },
		{ path: "earn/bank", page: Page_earn_bank },

        { path: "tools/airdrop", page: Page_soon },
        { path: "tools/marketplace", page: Page_soon },
        { path: "tools/escrow", page: Page_tools_escrow },

        { path: "analytics/protocol", page: Page_analytics_protocol },
        { path: "analytics/user", page: Page_analytics_user },

        { path: "learn", page: Page_soon },

        { path: "referral", page: Page_misc_referral }
    ]
};

export default configPage;