import { useMemo } from "react";

//components
import { Flex, Grid, Image, Text } from "@MoonLabsDev/dapp-core-lib";
import { ProductCard } from "../ProductCard";

const TokenUseCase = ({value: {icon, title, text}}) =>
{
	return (
		<Flex style={{ height: "100% "}}>
			<Image
				style=
				{
					{
						width: "32px",
						height: "32px",
						marginLeft: "10px",
						marginRight: "10px"
					}
				}
				src={icon}
			/>

			<Grid
				gap="0px"
				style={{ flexGrow: 1 }}
			>
				<Text>
					{title}
				</Text>

				<Text
					color={2}
					size={-1}
				>
					{text}
				</Text>
			</Grid>
		</Flex>
	);
};

export const ProductCard_Token = () =>
{
	//functions
	const getData = () =>
	{
		return {
			title: "Token",
			description: "A unique token that can't loose in value. Holders gain early access & additional utility.",
			image: "/assets/page/home/product-token-bg.png",
			link: "/earn/token",
			data:
			[
				{
					component: TokenUseCase,
					value:
					{
						icon: "/assets/page/earn/token/utility_governance.svg",
						title: "Governance",
						text: "Additional voting power"
					}
				},
				{
					component: TokenUseCase,
					value:
					{
						icon: "/assets/page/earn/token/utility_staking.svg",
						title: "Exclusive Offers",
						text: "Access to specials offers"
					}
				},
				{
					component: TokenUseCase,
					value:
					{
						icon: "/assets/page/earn/token/utility_earlyAccess.svg",
						title: "Early Access",
						text: "Try out new features"
					}
				}
			]
		};
	};

	//state
	const data = useMemo(() => getData(), []);

	return (
		<ProductCard
			data={data}
		/>
	);
};