//components
import
{
    Grid,
	Flex,
	useIsMobile
} from "@MoonLabsDev/dapp-core-lib";
import
{
	BankUserAssetsOverview,
	BankUserAssetsChart,
	BankUserDeposit
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";
import styles from "../Pages.module.css"

export const Page_earn_bank = () =>
{
	const isMobile = useIsMobile();
    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Bank"
					backgroundImage="url(/assets/page/earn/bank/header.jpg)"
				>
					Stash your funds or withdraw your payouts from vaults and referral.
				</MVPageHeader>
			}
		>
            <MVCheckModule module="bank">
				<Flex align="normal">
					<BankUserAssetsOverview
						style={{ flexGrow: 1}}
					/>

					<BankUserDeposit
						className={{[styles.bankUserDeposit__mobile]: isMobile}}
						style={{ flexGrow: 1}}
					/>
				</Flex>

				<BankUserAssetsChart />
			</MVCheckModule>
        </MVPage>
    );
}