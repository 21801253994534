import { useState, useEffect, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";

//components
import
{
	Card,
    CardBody,
    CardHeader,
    cx,
    Flex,
	useIsMobile,
} from "@MoonLabsDev/dapp-core-lib";
import { EscrowTrade, EscrowUserOverview } from "@MoonVault/dapp-widgets-lib";

//classes
import { TradeStatus } from "@MoonVault/dapp-widgets-lib";
import { MVCheckModule, MVPage, MVPageHeader } from "src/dApp/components";

//styles
import styles from "../Pages.module.css";

const EscrowContent = () =>
{
	//context
	const [searchParams, setSearchParams] = useSearchParams();
	const isMobile = useIsMobile();
	const tradeRef = useRef();

    //state
    const [trade, setTrade] = useState(() => parseInt(searchParams.get("trade")) || null);
	const isTradeActive = useMemo(() => typeof trade === "number", [trade]);

	//handler
	const handleSelectTrade = (_tradeId) =>
	{
		searchParams.set("trade", _tradeId);
		setSearchParams(searchParams);
	}

    //effects
    useEffect(() =>
    {
		if (searchParams.has("trade"))
		{
			setTrade(parseInt(searchParams.get("trade")) || null);
			setTimeout(() => tradeRef.current?.scrollInto(), 100);
		}
    }, [searchParams]);

	return (
		<Flex
			direction="column"
			align="normal"
			className={cx([styles.escrowContainer, {[styles.escrowContainer__desktop]: !isMobile}])}
		>
			<Flex
				align="normal"
				responsive=
				{
					{
						md:
						{
							direction: "row"
						}
					}
				}
			>
				<Flex
					basis="fill-content"
					align="normal"
					className={cx([styles.currentTrade,
						{
							[styles.currentTrade__desktop]: !isMobile,
							[styles.currentTrade_Active]: isMobile && isTradeActive
						}])
					}
				>
					<EscrowTrade
						className={styles}
						tradeID={trade}
						ref={tradeRef}
					/>
				</Flex>
				<Flex direction="column" align="normal" style={{flex: 1}}>
					<EscrowUserOverview
						status={TradeStatus.ONGOING}
						currentTrade={trade}
						onSelectTrade={handleSelectTrade}
					/>
				</Flex>
			</Flex>
			<Card style={{flex: 1}}>
				<CardHeader>
					History
				</CardHeader>
				<CardBody>
					<Flex direction="row" style={{flex: 1}}>
						<EscrowUserOverview
							status={TradeStatus.COMPLETE}
							currentTrade={trade}
							onSelectTrade={handleSelectTrade}
						/>
						<EscrowUserOverview
							status={TradeStatus.CANCELLED}
							currentTrade={trade}
							onSelectTrade={handleSelectTrade}
						/>
					</Flex>
				</CardBody>
			</Card>
		</Flex>
	);
};

export const Page_tools_escrow = () =>
{

    return (
        <MVPage>
            <MVPageHeader
                title="Escrow"
				backgroundImage="url(/assets/page/tools/escrow/header.jpg)"
            >
                An escrow is a service where 2 people can trade with each other and exchange goods.
                <br />
                Escrows significantly reduce the risk of getting scammed.
                <br />
                Contact us to get your token or NFT collection whitelisted.
            </MVPageHeader>
			<MVCheckModule module="escrow">
				<EscrowContent />
			</MVCheckModule>
        </MVPage>
    )
};