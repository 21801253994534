import { useMemo, useState } from "react";
import { ResponsiveContainer } from "recharts";

//components
import
{
    Grid,
	Flex,
	Card,
	CardBody,
	Text,
	FormatFiatAmount,
	ValueTokenBalance,
	MLFormat,
	ValueTokenAmount,
	TextToggle
} from "@MoonLabsDev/dapp-core-lib";
import
{
	useMoonToken,
	useMoonPool,
	LineChart_VaultV1UserChainHistory
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVCheckModule
} from "../../dApp/components";

//framework
import { useMoonAPI, useVaultV1 } from "@MoonVault/dapp-widgets-lib";

//styles
import styles from "../Pages.module.css";

const CardChart_Vault = () =>
{
	//state
	const [sum, setSum] = useState(() => true);

	return (
		<Card className={styles.chart}>
			<CardBody>
				<Grid>
					<Flex justify="space-between">
						<Text>
							Vault User TVL:
						</Text>

						<TextToggle
							values=
							{
								[
									{ text: "Sum", sum: true },
									{ text: "Overview", sum: false }
								]
							}
							selectedIndex={(sum ? 0 : 1)}
							onChange={(v, i) => setSum(v.sum)}
						/>
					</Flex>
					<div style={{ width: "100%", height: 200 }}>
						<ResponsiveContainer>
							<LineChart_VaultV1UserChainHistory
								width={500}
								height={200}
								sum={sum}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const TooltipVoteBreakdown = () =>
{
	//context
	const api = useMoonAPI();

	//state
	const compoundPower = useMemo(() => api.userGovernance.breakdown.chains.reduce((p, c) => Math.max(c.compounds, p), 0), [api.userGovernance]);
	const eventPower = useMemo(() => api.userGovernance.breakdown.events.reduce((p, c) => c.value + p, 0), [api.userGovernance]);

	const unit = 1000000;
	return (
		<Grid>
			<DetailStat
				title="Membership"
			>
				{MLFormat.formatFloat(api.userGovernance.breakdown.firstDeposit / unit, 2, true)}
			</DetailStat>

			<DetailStat
				title="Compounding"
			>
				{MLFormat.formatFloat(compoundPower / unit, 2, true)}
			</DetailStat>

			<DetailStat
				title="Staked MOON"
			>
				{MLFormat.formatFloat(api.userGovernance.breakdown.moon / unit, 2, true)}
			</DetailStat>

			<DetailStat
				title="Events"
			>
				{MLFormat.formatFloat(eventPower / unit, 2, true)}
			</DetailStat>

			<DetailStat
				title="Total Voting Powert"
			>
				{MLFormat.formatFloat(api.userGovernance.power, 2, true)}
			</DetailStat>
		</Grid>
	);
};

const CardChain = () =>
{
	//context
	const vaults = useVaultV1();
	const moon = useMoonToken();
	const api = useMoonAPI();
	const pool = useMoonPool();

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text
						color={3}
						size={1}
						align="center"
					>
						User Stats
					</Text>

					<Grid
						cols={2}
						align="start"
					>
						<DetailList
							title="General"
						>
							<DetailStat
								title="Total Deposit"
							>
								<FormatFiatAmount
									value={vaults?.userDepositUSD}
								/>
							</DetailStat>

							<DetailStat
								title="Total Pending"
							>
								<FormatFiatAmount
									value={vaults?.userPendingUSD}
								/>
							</DetailStat>

						</DetailList>

						<DetailList
							title="MOON"
						>
							<DetailStat
								title="MOON Balance"
							>
								<ValueTokenBalance
									token={moon?.token}
									load={true}
									additionalLoad={!!moon?.initializedUser}
									precision={0}
								/>
							</DetailStat>

							{pool &&
								<DetailStat
									title="MOON Staked"
								>
									<ValueTokenAmount
										token={pool?.depositToken}
										value={pool?.userDeposit}
										load={true}
										additionalLoad={!!pool?.initializedUser}
										precision={0}
									/>
								</DetailStat>
							}

							<DetailStat
								title="Voting Power"
							>
								{MLFormat.formatFloat(api.userGovernance?.power || 0, 2, true)}
							</DetailStat>
						</DetailList>

					</Grid>
				</Grid>
			</CardBody>
		</Card>
	);
};

const DetailList = ({title, children}) =>
{
	return (
		<Grid
			gap="0px"
			align="start"
		>
			<Text
				size={-1}
				color={3}
			>
				{title}:
			</Text>
			{children}
		</Grid>
	);
};

const DetailStat = ({title, children}) =>
{
	return (
		<Flex justify="space-between">
			<Text
				size={-1}
				color={1}
			>
				{title}:
			</Text>

			<Text
				size={-1}
				color={3}
			>
				{children}
			</Text>
		</Flex>
	);
};

export const Page_analytics_user = () =>
{
    //hooks
    const api = useMoonAPI();

	return (
		<MVPage>
            <MVCheckModule module="moon_api">

				<CardChain />

				<CardChart_Vault />

			</MVCheckModule>
        </MVPage>
    );
}