//components
import
{
	Flex,
	FormatPercent,
	Grid,
	Text,
	TokenIcon
} from "@MoonLabsDev/dapp-core-lib";

export const ProductInfo_VaultV1 = ({value}) =>
{
	//helper
	const vault = value.vault;
	const top = value.top;
	const category = value.category;

	return (
		<Flex
			gap="0px"
			style=
			{
				{
					width: "100%",
					height: "100%"
				}
			}
		>
			<TokenIcon
				token={vault.depositToken}
				style=
				{
					{
						width: "32px",
						height: "32px",
						marginLeft: "10px",
						marginRight: "10px"
					}
				}
			/>

			<Grid
				gap="0px"
				style={{ flexGrow: 1 }}
			>
				<Text>
					{vault.depositToken.symbol}
				</Text>

				<Text
					size={-1}
					color={2}
				>
					<FormatPercent
						value={vault.combinedAPR}
					/>
					{" "}APR
				</Text>
			</Grid>

			<Grid
				gap="0px"
				style=
				{
					{
						marginLeft: "auto",
						marginRight: "10px",
						width: "50px"
					}
				}
			>
				<Text
					color={3}
					size={0}
					align="center"
				>
					#{top}
				</Text>

				<Text
					color={1}
					size={-1}
					align="center"
				>
					{category}
				</Text>
			</Grid>
		</Flex>
	);
};