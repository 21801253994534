import { useMemo } from "react";

//components
import
{
    Text,
	Link,
    Card,
    CardBody,
    Flex,
} from "@MoonLabsDev/dapp-core-lib";

//styles
import styles from "./Product.module.css";

const Product = ({title, children, image, link, external, data, className}) =>
{
	//props
	const p_data = data || [];

    return (
        <Card
            style=
            {
                {
                    width: "300px",
                    height: "400px",
                }
            }
        >
            <CardBody
				className={className}
				style=
				{
					{
						width: "300px",
						height: "400px",
						backgroundImage: `url(${image})`,
						backgroundSize: "cover"
					}
				}
			>
                <Flex
					gap="0"
					style={{ height: "100%" }}
					direction="column"
				>
					<Text size={1}>
						{title}
					</Text>

					<Text
						size={0}
						align="center"
						className={styles.productDescription}
					>
						{children}
					</Text>

					<Flex as="ul" direction="row" className={styles.productWells}>
						{p_data.map(d =>
							<li
								key={`item_${p_data.indexOf(d)}`}
								className={styles.productWell}
							>
								{<d.component value={d.value} />}
							</li>
						)}
					</Flex>
					<div style={{ flexGrow: 1 }} />
					<Link
						href={link}
						nav={!external}
						size={0}
						className={styles.productSeeAll}
					>
						See All &gt;
					</Link>
				</Flex>
            </CardBody>
        </Card>
    );
};

export const ProductCard = ({data}) =>
{
	return (
		<>
			{!!data &&
				<Product
					title={data.title}
					image={data.image}
					link={data.link}
					external={data.external}
					data={data.data}
				>
					{data.description}
				</Product>
			}
		</>
	);
};