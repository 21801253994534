import { useState, useMemo } from "react";
import { ResponsiveContainer } from "recharts";

//components
import
{
    Grid,
	Flex,
	TextToggle,
	Card,
	CardBody,
	Text,
	FormatFiatAmount,
	FormatPercent,
	MLWeb3
} from "@MoonLabsDev/dapp-core-lib";
import
{
    LineChart_TVL,
    LineChart_MoonToken,
    MoonTokenMetric,
	PieChart_Fees
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVCheckModule
} from "../../dApp/components";

//framework
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonAPI, useVaultV1 } from "@MoonVault/dapp-widgets-lib";

const CardFees = () =>
{
	return (
		<Card>
			<CardBody>
				<PieChart_Fees />
			</CardBody>
		</Card>
	);
};

const CardToken = () =>
{
	//const
	const info =
	[
		{ text: "Price", showInfo: MoonTokenMetric.UNIT_PRICE },
		{ text: "Supply", showInfo: MoonTokenMetric.CIRUCLATING_SUPPLY },
		{ text: "Pegged", showInfo: MoonTokenMetric.PEGGED_BALANCE },
		{ text: "All", showInfo: MoonTokenMetric.ALL }
	];

	//state
	const [showInfo, setShowInfo] = useState(() => MoonTokenMetric.UNIT_PRICE);

	return (
		<Card>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						style={{ height: "42px" }}
					>
						<Text>
							MOON info:
						</Text>

						<TextToggle
							values={info}
							selectedIndex={info.indexOf(info.find(i => i.showInfo === showInfo))}
							onChange={(v, i) => setShowInfo(v.showInfo)}
						/>
					</Flex>
					<div style={{ width: "100%", height: 300 }}>
						<ResponsiveContainer>
							<LineChart_MoonToken
								width={950}
								height={300}
								metrics={showInfo}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const CardTVL = () =>
{
	//context
	const dApp = useDApp();

	//state
	const [tvl, setTVL] = useState(() => 0);
	const tvlUSD = useMemo(() => MLWeb3.toBN(tvl).mul(dApp.stableCoin.one), [tvl]);

	//handler
	const handleDataChange = (_data) =>
	{
		if (!_data?.length)
		{
			return;
		}
		const d = _data[_data.length - 1];
		const t = Object.entries(d).reduce((p, [key, value]) => p + (key !== "x" ? value : 0), 0);
		setTVL(t);
	};

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text>
						TVL:{" "}
						<FormatFiatAmount
							value={tvlUSD}
						/>
					</Text>
					<div style={{ width: "100%", height: 300 }}>
						<ResponsiveContainer>
							<LineChart_TVL
								width={950}
								height={300}
								onDataChange={handleDataChange}
							/>
						</ResponsiveContainer>
					</div>
				</Grid>
			</CardBody>
		</Card>
	);
};

const CardChain = () =>
{
	//context
	const dApp = useDApp();
	const vaults = useVaultV1();

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text
						color={3}
						size={1}
						align="center"
					>
						Protocol Stats ({dApp.currentChain.name})
					</Text>

					<Grid
						cols={2}
						align="start"
					>
						<DetailList
							title="Protocol Stats"
						>
							<DetailStat
								title="TVL"
							>
								<FormatFiatAmount
									value={vaults?.totalDepositUSD}
								/>
							</DetailStat>

							<DetailStat
								title="Daily Protocol Profit"
							>
								<FormatFiatAmount
									value={vaults?.dailyProtocolProfitUSD}
								/>
							</DetailStat>

							<DetailStat
								title="Min APR"
							>
								<FormatPercent
									value={vaults?.minAPR}
								/>
							</DetailStat>

							<DetailStat
								title="Max APR"
							>
								<FormatPercent
									value={vaults?.maxAPR}
								/>
							</DetailStat>

						</DetailList>

						<DetailList
							title="Vault Stats"
						>
							<DetailStat
								title="Vaults"
							>
								{vaults?.vaults?.length || 0}
							</DetailStat>

							<DetailStat
								title="Total Pending"
							>
								<FormatFiatAmount
									value={vaults?.totalPendingUSD}
								/>
							</DetailStat>

							<DetailStat
								title="Profit Fee"
							>
								<FormatPercent
									value={vaults?.fee_total}
								/>
							</DetailStat>

							<DetailStat
								title="Withdraw Fee"
							>
								<FormatPercent
									value={vaults?.fee_withdraw}
								/>
							</DetailStat>
						</DetailList>

					</Grid>
				</Grid>
			</CardBody>
		</Card>
	);
};

const DetailList = ({title, children}) =>
{
	return (
		<Grid
			gap="0px"
			align="start"
		>
			<Text
				size={-1}
				color={3}
			>
				{title}:
			</Text>
			{children}
		</Grid>
	);
};

const DetailStat = ({title, children}) =>
{
	return (
		<Flex justify="space-between">
			<Text
				size={-1}
				color={1}
			>
				{title}:
			</Text>

			<Text
				size={-1}
				color={3}
			>
				{children}
			</Text>
		</Flex>
	);
};

export const Page_analytics_protocol = () =>
{
    //hooks
    const api = useMoonAPI();

	return (
		<MVPage>
            <MVCheckModule module="moon_api">

				<CardChain />

				<CardTVL />

				<CardToken />

			</MVCheckModule>
        </MVPage>
    );
}