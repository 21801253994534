import { config } from "@MoonVault/dapp-widgets-lib";
import configPage from "./page";
import configMenu from "./menu";
import configUI from "./ui";

export default
{
	...config,

	page: configPage,
	menu: configMenu,
	ui: configUI
};