import { useMemo, useState } from "react";

//components
import
{
    Text,
    Button,
    Grid,
    Flex,
	Image,
    Card,
    CardBody,
    InputTokenAmount,
	YouTubeIFrame,
	InputTokenAmount_Balance,
	CheckTransactionButton_Balance,
	useApproval
} from "@MoonLabsDev/dapp-core-lib";
import { ModalBuyMoonToken, MoonTokenPriceType } from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule,
	SectionHeader
} from "../../dApp/components";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useMoonToken, ModuleEvents_MoonToken } from "@MoonVault/dapp-widgets-lib";

//styles
import styles from "src/pages/Pages.module.css";

const Breakdown = () =>
{
	return (
		<Grid
			cols={1}
			responsive=
			{
				{
					sm:
					{
						cols: 2
					}
				}
			}
		>
			<Card style={{ height: "100%"}}>
				<CardBody>
					<Grid gap="0px">
						<Text
							color={3}
							size={1}
						>
							$MOON
						</Text>
						<Text
							color={1}
							size={-1}
						>
							Our token is a utility token to help our community grow and strive. You gain additional Governance points, early access to all new features and services and even get access to features that are exclusive to MOON holders.
						</Text>
					</Grid>

					<Grid
						gap="0px"
						style={{ marginTop: "auto", paddingTop: "25px" }}
					>
						<Text
							color={3}
							size={1}
						>
							KEY DISCLAIMERS
						</Text>
						<Text
							color={1}
							size={-1}
						>
							MOON is not meant to be an investment product but purely meant as a utility token and to support the project. You alway buy it at a loss and might not break even.
						</Text>
					</Grid>
				</CardBody>
			</Card>

			<Card style={{ height: "100%", minHeight: "250px" }}>
				<CardBody>
					<YouTubeIFrame videoID="jJB8zM3KHhg" />
				</CardBody>
			</Card>
		</Grid>
	);
};

const UtilityItem = ({title, image, link, children, external}) =>
{
    return (
		<Card style={{ height: "100%"}}>
			<CardBody>
				<Flex
					direction="column"
					style={{ height: "100%"}}
				>
					<Image
						style={{ height: "100px" }}
						src={image}
					/>

					<Grid gap="0px">
						<Text
							color={3}
							size={1}
							align="center"
						>
							{title}
						</Text>

						<Text
							color={1}
							size={-1}
							align="center"
						>
							{children}
						</Text>
					</Grid>

					<Button
						style={{ marginTop: "auto"}}
						href={link}
						nav={!external}
					>
						EXPLORE
					</Button>
				</Flex>
			</CardBody>
		</Card>
	);
};

const Utility = () =>
{
    return (
		<Grid
			cols={1}
			responsive=
			{
				{
					sm:
					{
						cols: 3
					}
				}
			}
		>
			<UtilityItem
				title="Governance"
				image="/assets/page/earn/token/utility_governance.svg"
				link="https://vote.moon-vault.com"
				external={true}
			>
				MOON token holders will gain Governance points based on their token holding, to vote on Community proposals
			</UtilityItem>

			<UtilityItem
				title="Staking"
				image="/assets/page/earn/token/utility_staking.svg"
				link="/earn/pool"
			>
				MOON can be staked in our pool for passive income. Protocol revenue is split amongst MOON holder who stake their MOON in the pool
			</UtilityItem>

			<UtilityItem
				title="Early Access"
				image="/assets/page/earn/token/utility_earlyAccess.svg"
			>
				MOON holders will be granted early access to new features and services that Moon Vault develops, aswell as some exclusive services
			</UtilityItem>
		</Grid>
	);
};

const Stat = (props) =>
{
    return (
        <Flex className={styles.earnToken_stats}>
            <Text size={-1}>
                {props.name}
            </Text>
            <Text size={-1}>
                {props.value}
            </Text>
        </Flex>
    );
};

const Buy = ({data}) =>
{
	//context
    const dApp = useDApp();
	const moon = useMoonToken();

	//state
	const [amountIn, setAmountIn] = useState(() => "");
	const [amountOut, setAmountOut] = useState(() => "");
	const [show, setShow] = useState(() => false);
	const amount = useMemo(() => MLWeb3.convertFloatString_TokenBN(amountIn, moon.token), [amountIn])

	//handler
	const handleAmountOutChanged = (amount) =>
	{
		const outVal = MLWeb3.convertFloatString_TokenBN(amount, moon.token);
		const inVal = moon.getPriceUSDForAmount(outVal, MoonTokenPriceType.BUY);
		setAmountIn(MLWeb3.convertTokenBN_FloatString(inVal, moon.peggedToken, true));
		setAmountOut(amount);
	};

	return (
		<Card>
			{show &&
				<ModalBuyMoonToken
					show={show}
					amount={MLWeb3.convertFloatString_TokenBN(amountOut, moon.token)}
					onClose={() => setShow(false)}
				/>
			}

			<CardBody>
				<Grid>
					<Text size={0}>
						Buy{" "}
						<Text color={3}>
							{moon.token?.symbol}
						</Text>
					</Text>

					<InputTokenAmount
						style={{ width: "100%" }}
                        token={moon.token}
						value={amountOut}
						showAvailable={true}
                        showMax={false}
						showUSD={false}
						available={data.availableSupply}
						availableText="For Sale"
						onChange={(e, v) => handleAmountOutChanged(v)}
                    />

					<Text size={0}>
						Pay{" "}
						<Text color={3}>
							{moon.peggedToken?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={moon.peggedToken}
						value={amountIn}
						showAvailable={true}
                        showMax={false}
						showUSD={false}
						placeholder="???"
						readOnly={true}
                    />

                    <CheckTransactionButton_Balance
                        className={styles.earnToken_button}
						token={moon.peggedToken}
						amount={amount}
						approveFor={moon.address}
						onClick={() => setShow(true)}
                    >
                        Buy
                    </CheckTransactionButton_Balance>

					<Stat
						name={"Buy Price"}
						value={dApp.formatFiat(data.buyPrice, 8)}
					/>
                </Grid>
			</CardBody>
		</Card>
	);
};

const Sell = ({data}) =>
{
	//context
    const dApp = useDApp();
	const moon = useMoonToken();

	//state
	const [amountIn, setAmountIn] = useState(() => "");
	const [amountOut, setAmountOut] = useState(() => "");
	const amount = useMemo(() => MLWeb3.convertFloatString_TokenBN(amountOut, moon.token), [amountOut]);

	//handler
	const handleAmountInChanged = (amount) =>
	{
		const inVal = MLWeb3.convertFloatString_TokenBN(amount, moon.token);
		const outVal = moon.getPriceUSDForAmount(inVal, MoonTokenPriceType.SELL);
		setAmountOut(MLWeb3.convertTokenBN_FloatString(outVal, moon.peggedToken, true));
		setAmountIn(amount);
	};
	const handleSell = () =>
	{
		moon.sell(MLWeb3.convertFloatString_TokenBN(amountOut, moon.token)).trySend();
	};

	return (
		<Card>
			<CardBody>
				<Grid>
					<Text size={0}>
						Redeem{" "}
						<Text color={3}>
							{moon.token?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={moon.token}
						value={amountIn}
						showAvailable={true}
                        showMax={true}
						onChange={(e, v) => handleAmountInChanged(v)}
                    />

					<Text size={0}>
						Receive{" "}
						<Text color={3}>
							{moon.peggedToken?.symbol}
						</Text>
					</Text>

					<InputTokenAmount_Balance
						style={{ width: "100%" }}
                        token={moon.peggedToken}
						value={amountOut}
						showAvailable={true}
                        showMax={true}
						placeholder="???"
						readOnly={true}
                    />

                    <CheckTransactionButton_Balance
                        className={styles.earnToken_button}
						token={moon.token}
						amount={amount}
						approveFor={moon.address}
						onClick={() => handleSell()}
                    >
                        Redeem
                    </CheckTransactionButton_Balance>

                    <Stat
						name={"Sell Price"}
						value={dApp.formatFiat(data.sellPrice, 8)}
					/>
                </Grid>
			</CardBody>
		</Card>
	);
};

const BuySell = () =>
{
    //context
    const moon = useMoonToken();

    //function
    const getMooonTokenValues = () =>
    {
        return {
            buyPrice: moon.tokenPrice,
            sellPrice: moon.tokenValue,
            availableSupply: moon.availableSupply,
            circulatingSupply: moon.circulatingSupply
        };
    }

    //state
    const [data, setData] = useState(() => getMooonTokenValues());

    //effects
    useEventSubscription(ModuleEvents_MoonToken.data, () => setData(getMooonTokenValues()));

    return (
        <Grid
			cols={1}
			responsive=
			{
				{
					md:
					{
						cols: 2
					}
				}
			}
		>
			<Buy data={data} />

			<Sell data={data} />
        </Grid>
    );
};

const Warning = () =>
{
    return (
		<Card>
			<CardBody className={styles.earnToken_warning}>
				<Grid gap="0px">
					<Flex>
						<Text size={1}>
							Warning
						</Text>
					</Flex>
					<Flex>
						<Text
							style={{ color: "#000" }}
							align="center"
						>
							Our token has unique tokenomics. Please read our documentation before you invest!
						</Text>
					</Flex>
				</Grid>
			</CardBody>
		</Card>
    );
};

const MoonTokenContent = () =>
{
    return (
		<Flex>
			<Grid>
				<Warning />

				<MVCheckModule module="moon_token">
					<BuySell />
				</MVCheckModule>

				<SectionHeader>
					Utility
				</SectionHeader>
				<Utility />

				<SectionHeader>
					Breakdown
				</SectionHeader>
				<Breakdown />
			</Grid>
		</Flex>
    );
};

export const Page_earn_token = () =>
{
    return (
        <MVPage
			header=
			{
				<MVPageHeader
					title="MOON Token"
					learnMore="jJB8zM3KHhg"
					backgroundImage="url(/assets/page/earn/token/header.jpg)"
				>
					Unique tokenomics and a token that can't loose value!
					<br />
					A utility token for voting, early access, exclusive services &amp; offers.
				</MVPageHeader>
			}
		>
            <MoonTokenContent />
        </MVPage>
    );
}